<template>
  <div class="page1">
    <!--主体内容-->
    <div class="contenCorrectRecord">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Subsystem')">
                <el-select
                  v-model.trim="formInline.systemCode"
                  filterable
                  size="15"
                  placeholder="请选择"
                  clearable
                >
                  <!-- <el-option label="全部" value=""></el-option> -->
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in subSystems"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.First_level_menu')">
                <el-select
                  v-model.trim="formInline.topMenuCode"
                  filterable
                  size="15"
                  placeholder="请选择"
                  clearable
                >
                  <!-- <el-option label="全部" value=""></el-option> -->
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in topMenus"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Secondary_menu')">
                <el-select
                  v-model.trim="formInline.menuCode"
                  filterable
                  size="15"
                  placeholder="请选择"
                  clearable
                >
                  <!-- <el-option label="全部" value=""></el-option> -->
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in menus"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="操作人:">
                <el-autocomplete
                  class="inline-input"
                  v-model="formInline.operatorName"
                  :fetch-suggestions="getOperators"
                  placeholder="请输入内容"
                  value-key="bossManagerName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>

              <!-- <el-form-item :label="$t('searchModule.Operator')">
            <el-input v-model.trim="formInline.operatorName" placeholder="请输入操作人"></el-input>
          </el-form-item>-->

              <el-form-item :label="$t('searchModule.Operation_type')" placeholder="请选择">
                <el-select v-model.trim="formInline.operationType">
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in operationTypes"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="$t('searchModule.Date_search')">
                <el-select
                  v-model.trim="formInline.dateType"
                  filterable
                  placeholder
                  @change="getTimeNow"
                >
                  <el-option label="日" value="day"></el-option>
                  <el-option label="月" value="month"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label prop="date">
                <el-date-picker
                  v-show="formInline.dateType == 'day'"
                  v-model="formInline.day1"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日"
                ></el-date-picker>
                <span v-show="formInline.dateType == 'day'">-</span>
                <el-date-picker
                  v-show="formInline.dateType == 'day'"
                  v-model="formInline.day2"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日"
                ></el-date-picker>
                <el-date-picker
                  v-show="formInline.dateType == 'month'"
                  v-model="formInline.date1"
                  type="month"
                  value-format="yyyy-MM-dd"
                  placeholder="选择月"
                ></el-date-picker>
                <span v-show="formInline.dateType == 'month'">-</span>
                <el-date-picker
                  v-show="formInline.dateType == 'month'"
                  v-model="formInline.date2"
                  type="month"
                  value-format="yyyy-MM-dd"
                  placeholder="选择月"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportFile()"
                v-if="$route.meta.authority.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <!-- <el-table-column label="操作" width="70">
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)">详情</el-button>
            </template>
          </el-table-column>-->
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
// import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "systemLog",
  data() {
    // const startTime = new Date();
    // startTime.setTime(startTime.getTime() - 3600 * 1000 * 24 * 31);
    // const date = new Date();
    // date.setHours(23);
    // date.setMinutes(59);
    // date.setSeconds(59);
    // let endTime = date;
    // endTime.setTime(endTime.getTime() - 3600 * 1000 * 24 * 1);
    return {
      temParkData: {},
      subSystems: [],
      topMenus: [],
      menus: [],
      operationTypes: [],
      operators: [],
      exportData: {},
      tableCols: [
        {
          prop: "systemName",
          label: "子系统",
          width: "",
        },
        {
          prop: "topMenuName",
          label: "一级模块",
          width: "",
        },
        {
          prop: "menuName",
          label: "二级模块",
          width: "",
        },
        {
          prop: "operationTypeName",
          label: this.$t("list.Operation_type"),
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "logTime",
          label: this.$t("list.Operation_time"),
          width: "",
          formatter: (row) => {
            return row.logTime;
          },
        },
        {
          prop: "logContent",
          label: this.$t("list.Operation_content"),
          width: "280px",
        },
      ],
      valueDate: "",
      formInline: {
        systemCode: "",
        topMenuCode: "",
        menuCode: "",
        operationType: "",
        operatorId: "",
        operatorName: "",
        dateType: "day",
        date1: "",
        date2: "",
        day1: "",
        day2: "",
      },
      tableData: [],
      pageSize: 15,
      page: 1,
      loading: false,
      total: 0,
    };
  },
  watch: {
    "formInline.systemCode": {
      deep: true,
      handler: function (newV, oldV) {
        this.formInline.topMenuCode = "";
        this.getTopMenuList();
      },
    },
    "formInline.topMenuCode": {
      deep: true,
      handler: function (newV, oldV) {
        this.formInline.menuCode = "";
        this.getMenuList();
      },
    },
    "formInline.operatorName": {
      deep: true,
      handler: function (newV, oldV) {
        if (!newV) {
          this.formInline.operatorId = "";
          this.temParkData = {};
        }
      },
    },
  },
  methods: {
    /* 一级模块下拉框 */
    getSubSystemList() {
      this.$axios
        .get("/acb/2.0/systemLog/systemCode", {
          data: {},
        })
        .then((res) => {
          // this.subSystems = res;
          if (res.state == 0) {
            this.subSystems = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    /* 一级模块下拉框 */
    getTopMenuList() {
      this.$axios
        .get("/acb/2.0/systemLog/oneLevel", {
          data: { systemCode: this.formInline.systemCode },
        })
        .then((res) => {
          if (res.state == 0) {
            this.topMenus = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    /* 二级模块下拉框 */
    getMenuList() {
      this.$axios
        .get("/acb/2.0/systemLog/twoLevel", {
          data: { topMenuCode: this.formInline.topMenuCode },
        })
        .then((res) => {
          if (res.state == 0) {
            this.menus = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    /* 操作类型下拉框 */
    getOperationTypes() {
      this.$axios
        .get("/acb/2.0/systemLog/getAddOption", {
          data: {},
        })
        .then((res) => {
          if (res.state == 0) {
            this.operationTypes = res.value.operationType;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    /* 操作人下拉框 */
    getOperators(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/bossManager/listVO", {
          data: {
            page: 1,
            size: 15,
            bossManagerName: queryString,
          },
        })
        .then((res) => {
          if (res.state == 0 && res.value.list && res.value.list.length) {
            this.temParkData = res.value.list.length
              ? {
                  operatorId: res.value.list[0].bossManagerId,
                  operatorName: res.value.list[0].bossManagerName,
                }
              : { operatorId: "", operatorName: "" };
            this.formInline.operatorId = "";
            // this.formInline.operatorId = "";
            // this.formInline.operatorName = "";
            cb(res.value.list);
          } else {
            cb([]);
            if (res.state != 0) {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          }
        });
    },

    handleSelect(item) {
      // this.formInline.operatorName = item.bossManagerName;
      this.formInline.operatorId = item.bossManagerId;
    },
    getTimeNow() {
      if (this.formInline.dateType == "day") {
        this.formInline.day1 = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
        this.formInline.day2 = this.$moment().startOf("day").format("YYYY-MM-DD");
      } else {
        this.formInline.date1 = this.$moment().startOf("month").format("YYYY-MM-DD");
        this.formInline.date2 = this.$moment().endOf("month").format("YYYY-MM-DD");
      }
      // const startTime = new Date();
      // const endTime = new Date();
      // if (this.formInline.dateType == "day") {
      //   startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
      //   endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
      //   this.formInline.day1 = dateFormat(startTime, "yyyy-MM-dd");
      //   this.formInline.day2 = dateFormat(endTime, "yyyy-MM-dd");
      // } else {
      //   startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
      //   endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
      //   startTime.setDate(1);
      //   endTime.setDate(1);
      //   this.formInline.date1 = dateFormat(startTime, "yyyy-MM-dd");
      //   this.formInline.date2 = dateFormat(endTime, "yyyy-MM-dd");
      // }
    },
    indexMethod(index) {
      return index + 1;
    },

    getStartOfDay(day) {
      //      let daySart=new Date(new Date(new Date().toLocaleDateString()).getTime())
      // let dayEnd=new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      // console.log(daySart);
      // console.log(dayEnd);
      let startLong = Date.parse(day.replace(/-/g, "/"));
      let start = new Date(new Date(new Date(startLong).toLocaleDateString()).getTime());
      return dateFormat(start, "yyyy-MM-dd HH:mm:ss");
    },

    getEndOfMonth(day) {
      var now = new Date(Date.parse(day.replace(/-/g, "/"))); // 当前日期
      var nowMonth = now.getMonth(); // 当前月
      var nowYear = now.getFullYear(); // 当前年
      // 本月的结束时间
      var monthEndDate = new Date(nowYear, nowMonth + 1, 0, 23, 59, 59);
      // monthEndDate.setHours(23);
      // monthEndDate.setMinutes(59);
      // monthEndDate.setSeconds(59);
      return dateFormat(monthEndDate, "yyyy-MM-dd HH:mm:ss");
    },

    getEndOfDay(day) {
      //      let daySart=new Date(new Date(new Date().toLocaleDateString()).getTime())
      // let dayEnd=new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      // console.log(daySart);
      // console.log(dayEnd);
      let startLong = Date.parse(day.replace(/-/g, "/"));
      let start = new Date(
        new Date(new Date(startLong).toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
      );
      return dateFormat(start, "yyyy-MM-dd HH:mm:ss");
    },

    // 首页列表查询
    searchData() {
      if (!this.formInline.operatorId && Object.keys(this.temParkData).length) {
        this.formInline.operatorName = this.temParkData.operatorName;
        this.formInline.operatorId = this.temParkData.operatorId;
        this.temParkData = {};
      }
      if (this.formInline.dateType == "month" && !this.formInline.date1) {
        this.$alert("开始月份不能为空！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.formInline.dateType == "month") {
        if (!this.formInline.date2) {
          this.$alert("结束月份不能为空！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
      }
      if (this.formInline.dateType == "day" && !this.formInline.day1) {
        this.$alert("开始日期不能为空！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.formInline.dateType == "day") {
        if (!this.formInline.day2) {
          this.$alert("结束日期不能为空！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
      }
      //   let url = "";
      if (this.formInline.dateType == "month") {
        let diff = new Date(this.formInline.date1) - new Date(this.formInline.date2).getTime();
        let days = Math.abs(diff / (24 * 60 * 60 * 1000));
        if (diff > 0) {
          this.$alert("开始日期不能大于结束日期", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        } else if (days > 365) {
          this.$alert("只能查询12个月的记录", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        } else {
          //   url = "/acb/2.0/parkingAnalysis/overview";
        }
      } else {
        let diff = new Date(this.formInline.day1) - new Date(this.formInline.day2).getTime();
        let days = Math.abs(diff / (24 * 60 * 60 * 1000));
        if (diff > 0) {
          this.$alert("开始日期不能大于结束日期", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        } else if (days > 30) {
          this.$alert("只能查询31天的记录", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        } else {
          //   url = "/acb/2.0/parkingAnalysis/overview";
        }
      }
      this.$axios
        // .get("bacb/2.0/plateCorrectRecord/list", {
        .get("/acb/2.0/systemLog/query", {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            systemCode: this.formInline.systemCode,
            topMenuCode: this.formInline.topMenuCode,
            menuCode: this.formInline.menuCode,
            operationType: this.formInline.operationType,
            operatorId: this.formInline.operatorId,
            // operatorName: this.formInline.operatorName,
            logTimeBegin:
              this.formInline.dateType == "month"
                ? this.getStartOfDay(this.formInline.date1)
                : this.getStartOfDay(this.formInline.day1),
            logTimeEnd:
              this.formInline.dateType == "month"
                ? this.getEndOfMonth(this.formInline.date2)
                : this.getEndOfDay(this.formInline.day2),
          },
        })
        .then((res) => {
          if (res.state == 0 && res.value && res.value.list) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.tableData = [];
            this.total = 0;
            if (res.state != 0) {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          }
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    exportFile() {
      let opt = {
        pageNum: this.page,
        pageSize: this.pageSize,
        topMenuCode: this.formInline.topMenuCode,
        systemCode: this.formInline.systemCode,
        menuCode: this.formInline.menuCode,
        operationType: this.formInline.operationType,
        // operatorName: this.formInline.operatorName,
        // dateType: this.formInline.dateType == "day" ? "1" : "2",
        logTimeBegin:
          this.formInline.dateType == "month"
            ? this.getStartOfDay(this.formInline.date1)
            : this.getStartOfDay(this.formInline.day1),
        logTimeEnd:
          this.formInline.dateType == "month"
            ? this.getEndOfMonth(this.formInline.date2)
            : this.getEndOfDay(this.formInline.day2),
        name: `系统日志`,
      };
      exportExcelNew("/acb/2.0/systemLog/export", opt, "post");
    },

    resetForm() {
      this.formInline.systemCode = "";
      this.formInline.topMenuCode = "";
      this.formInline.menuCode = "";
      this.formInline.operationType = "";
      this.formInline.operatorId = "";
      this.formInline.operatorName = "";
      this.temParkData = {};
      if (this.formInline.dateType == "day") {
        this.getTimeNow();
      } else {
        this.formInline.dateType = "day";
      }
    },
  },
  // components: {
  //   exportFile,
  // },

  mounted() {
    this.getTimeNow();
    this.searchData();
    this.getSubSystemList();
    this.getOperationTypes();
    // this.exportData = {
    //   url: "/acb/2.0/systemLog/export",
    //   methods: "post",
    //   data: {
    //     pageNum: this.page,
    //     pageSize: this.pageSize,
    //     topMenuCode: this.formInline.topMenuCode,
    //     menuCode: this.formInline.menuCode,
    //     operationType: this.formInline.operationType,
    //     operatorName: this.formInline.operatorName,
    //     // dateType: this.formInline.dateType == "day" ? "1" : "2",
    //     logTimeBegin:
    //       this.formInline.dateType == "month"
    //         ? this.formInline.date1
    //         : this.formInline.day1,
    //     logTimeEnd:
    //       this.formInline.dateType == "month"
    //         ? this.formInline.date2
    //         : this.formInline.day2,
    //     name: `系统日志`,
    //   },
    // };
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  // height: 35px;
}

.contenCorrectRecord {
  overflow: hidden;
  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}
</style>
<style lang="stylus">
.contenCorrectRecord .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}

.contenCorrectRecord .el-dialog {
  width: 55%;
}

.contenCorrectRecord {
  .bg-purple {
    background-color: #e5e9f2;
    height: 30px;
    line-height: 30px;
    border: 1px solid;
    text-align: center;
  }

  .bg-purple-light {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    text-align: center;
  }

  .bg-purple-light-last {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    text-align: center;
    border-right: 1px solid;
  }

  .bg-purple-light-top {
    border-top: 0px solid;
  }

  .picBox {
    padding-top: 50px;
    box-sizing: border-box;
    text-align: center;
  }

  .pciTile {
    text-align: center;
    margin-top: 15px;
  }

  .btn {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
}
</style>
